import React, {useState, useEffect} from "react";
import {Grid, Step, StepLabel, Stepper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextInput from "./input/TextInput";
import AutocompleteInput from "./input/AutocompleteInput";
import SelectInput from "./input/SelectInput";
import DateInput from "./input/DateInput";
import DateTimeInput from './input/DateTimeInput'
import UserService from "../../services/user.service";
import {logOut} from "../../helpers/logout";
import {Redirect, useParams} from "react-router-dom";
import {NotificationManager} from 'react-notifications';
import {checkEqualityOfObjects} from '../../helpers/helper'
import Popup from "../presentation/Popup";
import towns from "../../assets/towns.json";

const initialValues = {
    contracting_authority: '',
    funeral_type: '',
    ca_address: '',
    ca_zip: '',
    ca_city: '',
    ca_state: '',
    ca_nationality: '',
    ca_email: '',
    ca_phone: '',
    ca_id: '',
    ca_birth: '',
    ca_relationship: '',
    deceased: '',
    d_address: '',
    d_zip: '',
    d_city: '',
    d_state: '',
    d_place_birth: '',
    d_gender: '',
    d_marital_status: '',
    d_birth: null,
    d_death: null,
    d_place_death: '',
    d_id: '',
    d_nationality: '',
    d_education: '',
    d_last_job: '',
    d_wife_husband: '',
    d_wife_husband_id: '',
    planned_pickup_place: '',
    coffin_arrival_place: '',
    planned_pickup: null,
    town_funeral: '',
    place_funeral: '',
    date_funeral: null,
    coffin_arrival: null,
    picked_items: '',
    notes: ''
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(8),
    },
    marginTop: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Obstarávateľ pohrebu', 'Zosnulá osoba', 'Detaily pohrebu'];
}

const propsDelegationCheck = [
    "contracting_authority", "ca_address", "ca_zip", "ca_city", "ca_state", "ca_nationality", "ca_phone",
    "ca_id", "ca_birth", "ca_relationship", "ca_email", "deceased", "d_address", "d_zip", "d_city", "d_state", "d_marital_status", "d_gender",
    "d_last_job", "d_id", "d_place_birth", "d_place_death", "d_education",
    "d_wife_husband", "d_wife_husband_id","d_birth", "d_death"
]
const propsItemsCheck = [
    "picked_items",
    "contracting_authority"
]

const propsValuablesCheck = [
    "contracting_authority"
]

const propsUrnCheck = [
    "deceased", "d_id",
    "contracting_authority"
]
const propsDocumentationCheck = [
    "contracting_authority"
]
const propsAllowCheck = [
    "town_funeral", "deceased", "d_id"
]

export default function FuneralForm(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.root);
    const seeMoreContainer = clsx(classes.seeMore, classes.root);

    const [values, setValues] = useState(initialValues);
    const [oldValues, setOldValues] = useState(initialValues);
    const [success, setSuccess] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [show, setShow] = useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setValues({...values, [name]: value});
    }

    const autocompleteChange = (event, vals) => {
        setValues({...values, ['town_funeral']: vals.title});
    }

    const params = useParams();
    const hasProperty = params.hasOwnProperty('funeral_id');

    useEffect(() => {
        if (params.hasOwnProperty('funeral_id')) {
            UserService.getFuneral(params.funeral_id).then(
                (response) => {
                    let object = response.data;
                    object.coffin_arrival = object.coffin_arrival && new Date(object.coffin_arrival);
                    object.d_birth = new Date(object.d_birth);
                    object.d_death = new Date(object.d_death);
                    object.planned_pickup = object.planned_pickup && new Date(object.planned_pickup);
                    object.date_funeral = object.date_funeral && new Date(object.date_funeral);
                    setValues(object);
                    setOldValues(object);
                    // POPUP O TOM ZE PODPISANE DOK BUDU VYMAZANE AK SA PREPISU
                    // if (object.signatures.delegation_signature || object.signatures.items_signature ||
                    //     object.signatures.valuables_signature || object.signatures.urn_signature
                    // || object.signatures.allow_signature || object.signatures.documentation_signature) {
                    //     setShow(true);
                    // }
                },
                (e) => {
                    if (e.response.status === 401) {
                        logOut(props);
                        NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                    }
                }
            );
        }
    }, [params, props]);

    const checkSignatures = (tag, propsToCheck) => {
        if (values.signatures[`${tag}_signature`]) {
            if (!checkEqualityOfObjects(values, oldValues, propsToCheck)) {
                let data = {
                    tag: tag,
                    signatures_id: values.signatures._id
                };
                UserService.unSignDocument(data)
                    .then(() =>{}, (e =>{console.log(e)}))
            }
        }
    }

    const handleCreate = (e) => {
        e.preventDefault();
        // if(hasProperty){
        //     VYMAZANIE PODPISU AK SA UPRAVILI DANE DOKUMENTY
        //     checkSignatures('delegation', propsDelegationCheck);
        //     checkSignatures('items', propsItemsCheck);
        //     checkSignatures('valuables', propsValuablesCheck);
        //     checkSignatures('documentation', propsDocumentationCheck);
        //     checkSignatures('urn', propsUrnCheck);
        //     checkSignatures('allow', propsAllowCheck);
        //
        // }
        if (validate()) {
            if(hasProperty){
                UserService.updateFuneral(values).then(
                    (response) => {
                        setSuccess(response.data._id);
                        NotificationManager.success('Pohreb bol upravený!', 'Vytvorené', 5000);
                    },
                    (e) => {
                        if (e.response.status === 401) {
                            logOut(props);
                            NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                        } else {
                            NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                        }
                        handleReset();
                    }
                );
            } else {
                UserService.createFuneral(values).then(
                    (response) => {
                        setSuccess(response.data._id);
                        NotificationManager.success('Pohreb bol vytvorený!', 'Vytvorené', 5000);
                    },
                    (e) => {
                        if (e.response.status === 401) {
                            logOut(props);
                            NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                        } else {
                            NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                        }
                        handleReset();
                    }
                );
            }
        } else {
            NotificationManager.error('Skontrolujte vyplnený formulár!', 'Chyba', 5000);
        }

    };

    const [errors, setErrors] = useState({});

    const validate = () => {
        let temp = {};
        temp.contracting_authority = values.contracting_authority ? "" : "Toto pole je potrebné vyplniť!";
        temp.ca_phone = values.ca_phone ? "" : "Toto pole je potrebné vyplniť!";
        temp.deceased = values.deceased ? "" : "Toto pole je potrebné vyplniť!";
        temp.ca_relationship = values.ca_relationship ? "" : "Toto pole je potrebné vyplniť!";
        temp.ca_birth = values.ca_birth ? "" : "Toto pole je potrebné vyplniť!";
        temp.ca_id = (/[0-9]{6}\/?[0-9]{3,4}/).test(values.ca_id) ? "" : "Zadajte požadovaný formát 123456/0000 alebo 1234560000. (prípadne 9 cifier)";
        temp.d_birth = values.d_birth ? "" : "Toto pole je potrebné vyplniť!";
        temp.d_place_birth = values.d_place_birth ? "" : "Toto pole je potrebné vyplniť!";
        temp.d_marital_status = values.d_marital_status ? "" : "Toto pole je potrebné vyplniť!";
        temp.d_gender = values.d_gender ? "" : "Toto pole je potrebné vyplniť!";
        temp.d_nationality= values.d_nationality ? "" : "Toto pole je potrebné vyplniť!";
        temp.d_education = values.d_education ? "" : "Toto pole je potrebné vyplniť!";
        temp.d_last_job = values.d_last_job ? "" : "Toto pole je potrebné vyplniť!";
        temp.d_death = values.d_death ? "" : "Toto pole je potrebné vyplniť!";
        temp.d_place_death = values.d_place_death ? "" : "Toto pole je potrebné vyplniť!";
        temp.d_id = (/[0-9]{6}\/?[0-9]{3,4}/).test(values.d_id) ? "" : "Zadajte požadovaný formát 123456/0000 alebo 1234560000. (prípadne 9 cifier)";
        temp.ca_email = (/$^|.+@.+..+/).test(values.ca_email) ? "" : "Email ma zlý formát!"
        setErrors({...temp})

        // eslint-disable-next-line eqeqeq
        return Object.values(temp).every(x => x == "")
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <Paper className={fixedHeightPaper}>
                    <TextInput
                        label="Meno a priezvisko"
                        placeholder="(Titul.) Meno Priezvisko (Titul.)"
                        name={'contracting_authority'}
                        value={values.contracting_authority}
                        error={errors.contracting_authority}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Adresa"
                        placeholder="Ulica a číslo"
                        name={'ca_address'}
                        value={values.ca_address}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="PSČ"
                        placeholder="PSČ"
                        name={'ca_zip'}
                        value={values.ca_zip}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Obec"
                        placeholder="Obec"
                        name={'ca_city'}
                        value={values.ca_city}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Štát"
                        placeholder="Štát"
                        name={'ca_state'}
                        value={values.ca_state}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Národnosť"
                        placeholder="Národnosť"
                        name={'ca_nationality'}
                        value={values.ca_nationality}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Email"
                        name={'ca_email'}
                        error={errors.ca_email}
                        value={values.ca_email}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Telefónne číslo"
                        name={'ca_phone'}
                        error={errors.ca_phone}
                        value={values.ca_phone}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Rodné číslo"
                        name={'ca_id'}
                        error={errors.ca_id}
                        value={values.ca_id}
                        onChange={handleChange}
                    />
                    <DateInput
                        label="Dátum narodenia"
                        name={'ca_birth'}
                        value={values.ca_birth}
                        error={errors.ca_birth}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Príbuzenský vzťah"
                        name={'ca_relationship'}
                        error={errors.ca_relationship}
                        value={values.ca_relationship}
                        onChange={handleChange}
                    />
                </Paper>
            case 1:
                return <Paper className={fixedHeightPaper}>
                    <Title>Zosnulý</Title>
                    <TextInput
                        label="Meno a priezvisko"
                        name={'deceased'}
                        placeholder="(Titul.) Meno Priezvisko (Titul.)"
                        value={values.deceased}
                        error={errors.deceased}
                        onChange={handleChange}
                    />
                    <SelectInput
                        label="Pohlavie"
                        name={'d_gender'}
                        error={errors.d_gender}
                        value={values.d_gender}
                        options={[
                            {value: '1 - muž', title: '1 - muž'},
                            {value: '2 - žena', title: '2 - žena'},
                            {value: '3 - iné', title: '3 - iné'},
                        ]}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Adresa"
                        placeholder="Ulica a číslo"
                        name={'d_address'}
                        value={values.d_address}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="PSČ"
                        placeholder="PSČ"
                        name={'d_zip'}
                        value={values.d_zip}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Obec"
                        placeholder="Obec"
                        name={'d_city'}
                        value={values.d_city}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Štát"
                        placeholder="Štát"
                        name={'d_state'}
                        value={values.d_state}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Národnosť"
                        name={'d_nationality'}
                        error={errors.d_nationality}
                        value={values.d_nationality}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Rodné číslo"
                        name={'d_id'}
                        error={errors.d_id}
                        value={values.d_id}
                        onChange={handleChange}
                    />
                    <DateInput
                        label="Dátum narodenia"
                        name={'d_birth'}
                        value={values.d_birth}
                        error={errors.d_birth}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Miesto narodenia"
                        name={'d_place_birth'}
                        error={errors.d_place_birth}
                        value={values.d_place_birth}
                        onChange={handleChange}
                    />
                    <DateInput
                        label="Dátum úmrtia"
                        name={'d_death'}
                        error={errors.d_death}
                        value={values.d_death}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Miesto úmrtia"
                        name={'d_place_death'}
                        error={errors.d_place_death}
                        value={values.d_place_death}
                        onChange={handleChange}
                    />

                    <SelectInput
                        label="Rodinný stav"
                        name={'d_marital_status'}
                        error={errors.d_marital_status}
                        value={values.d_marital_status}
                        options={[
                            {value: '1 - sloboný(-á)', title: '1 - sloboný(-á)'},
                            {value: '2 - ženatý, vydatá', title: '2 - ženatý, vydatá'},
                            {value: '3 - rozvedený(-á)', title: '3 - rozvedený(-á)'},
                            {value: '4 - ovdovený(-á)', title: '4 - ovdovený(-á)'},
                            {value: '5 - reg. partnerstvo', title: '5 - reg. partnerstvo'},
                        ]}
                        onChange={handleChange}
                    />
                    <SelectInput
                        label="Vzdelanie"
                        name={'d_education'}
                        error={errors.d_education}
                        value={values.d_education}
                        options={[
                            {value: 'Bez vzdelania', title: 'Bez vzdelania'},
                            {value: '1 - základné', title: '1 - základné'},
                            {value: '2 - stredné bez maturity', title: '2 - stredné bez maturity'},
                            {value: '3 - stredné s maturitou', title: '3 - stredné s maturitou'},
                            {value: '4 - vysokoškolské', title: '4 - vysokoškolské'},
                        ]}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Posledné zamestnanie"
                        name={'d_last_job'}
                        error={errors.d_last_job}
                        value={values.d_last_job}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Partner"
                        name={'d_wife_husband'}
                        value={values.d_wife_husband}
                        placeholder={'(Titul.) Meno Priezvisko (Titul.)'}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Rodné číslo partnera"
                        name={'d_wife_husband_id'}
                        value={values.d_wife_husband_id}
                        onChange={handleChange}
                    />
                </Paper>;
            case 2:
                return <Paper className={fixedHeightPaper}>
                    <Title>Detaily pohrebu</Title>

                    <DateTimeInput
                        label="Plánované vyzdvihnutie"
                        name={'planned_pickup'}
                        value={values.planned_pickup}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Adresa plánovaného vyzdvihnutia"
                        value={values.planned_pickup_place}
                        name={'planned_pickup_place'}
                        placeholder="Adresa"
                        onChange={handleChange}
                    />
                    <AutocompleteInput
                        label="Mesto/Obec pohrebu"
                        name={'town_funeral'}
                        value={values.town_funeral}
                        error={errors.d_place_birth}
                        options={towns}
                        onChange={autocompleteChange}
                    />
                    <TextInput
                        label="Miesto pohrebu"
                        value={values.place_funeral}
                        name={'place_funeral'}
                        placeholder="Dom smútku, cintorín, kostol ..."
                        onChange={handleChange}
                    />
                    <DateTimeInput
                        label="Dátum a čas pohrebu"
                        name={'date_funeral'}
                        value={values.date_funeral}
                        onChange={handleChange}
                    />
                    <DateTimeInput
                        label="Dátum a čas príjazdu rakvy"
                        name={'coffin_arrival'}
                        value={values.coffin_arrival}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Adresa príjazdu rakvy"
                        value={values.coffin_arrival_place}
                        name={'coffin_arrival_place'}
                        placeholder="Adresa"
                        onChange={handleChange}
                    />
                    <SelectInput
                        label="Typ pohrebu - výber z možností"
                        name={'funeral_type'}
                        value={values.funeral_type}
                        options={[
                            {value: 'Občiansky', title: 'Občiansky'},
                            {value: 'Rímskokatolícka cirkev', title: 'Rímskokatolícka cirkev'},
                            {value: 'Gréckokatolícka cirkev', title: 'Gréckokatolícka cirkev'},
                            {value: 'Pravoslávna cirkev', title: 'Pravoslávna cirkev'},
                            {value: 'Evanjelická cirkev', title: 'Evanjelická cirkev'},
                            {value: 'Náboženská spoločnosť Jehovovi svedkovia', title: 'Náboženská spoločnosť Jehovovi svedkovia'},
                            {value: 'Reformovaná kresťanská cirkev', title: 'Reformovaná kresťanská cirkev'},
                            {value: 'Babtistická bratská jednota', title: 'Babtistická bratská jednota'},
                            {value: 'Apoštolská cirkev', title: 'Apoštolská cirkev'}
                        ]}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Typ pohrebu - dopísať"
                        name={'funeral_type'}
                        value={values.funeral_type}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Prevzaté oblečenie a doplnky"
                        name={'picked_items'}
                        value={values.picked_items}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Poznámky ku pohrebu"
                        name={'notes'}
                        value={values.notes}
                        onChange={handleChange}
                    />
                </Paper>;
            default:
                return 'Unknown step';
        }
    }

    return (
        <form autoComplete='off' onSubmit={handleCreate}>
            <Container maxWidth="lg">
                <div className={seeMoreContainer}>
                    {hasProperty ? <Title>Úprava pohrebu</Title> : <Title>Vytvoriť nový pohreb</Title>}
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>

                        <div className={classes.marginTop}>
                            <Typography component={'span'}
                                        className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                            <div className={classes.marginTop}>
                                <Grid container>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <Button disabled={activeStep === 0} variant="contained" onClick={handleBack}
                                                className={classes.button}>
                                            Späť
                                        </Button>
                                        <Button
                                            id={'aa'}
                                            variant="contained"
                                            color="primary"
                                            type='submit'
                                            hidden={true}
                                            style={activeStep === steps.length - 1 ? {} : {display: 'none'}}
                                        >
                                            {hasProperty ? "Upraviť" : "Vytvoriť"}
                                        </Button>
                                        <Button
                                            id={'bb'}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            style={activeStep === steps.length - 1 ? {display: 'none'} : {}}
                                            className={classes.button}
                                        >
                                            Ďalej
                                        </Button>
                                    </Grid>
                                    <Grid item container xs={6} sm={6} md={6} lg={6} justifyContent='flex-end'>
                                        {hasProperty &&
                                        <Button
                                            id={'bb'}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                setSuccess(params.funeral_id)
                                            }
                                            }
                                        >
                                            Späť na detaily
                                        </Button>
                                        }
                                    </Grid>

                                </Grid>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
            <Popup
                title={'Upozornenie'}
                openPopup={show}
                setOpenPopup={setShow}
            >
                <Typography component="h2" variant="h6" color="secondary">
                    Pri úprave dát, ktoré sú uvedené pri podpísaných dokumentoch, sa podpis pri dokumente vymaže!
                </Typography>
                <Typography component="div" variant="subtitle1">
                    Dáta pre Splnomocnenie
                </Typography>
                <Typography component="div" variant="body2">
                    &emsp;<span style={{fontWeight: "bold"}}>Obstarávateľ pohrebu:</span> meno, adresa, email, tel. číslo,
                    rodné číslo, príbuzenský vzťah
                </Typography>
                <Typography component="div" variant="body2">
                    &emsp;<span style={{fontWeight: "bold"}}>Zosnulý:</span> meno, rodné číslo, dátum a miesto narodenia a úmrtia,
                    posledné zamestnanie, dosiahnuté vzdelanie, rodinný stav, meno a rodné číslo partnera
                </Typography>
                <Typography component="div" variant="subtitle1">
                    Dáta pre Dokumenty na podpis
                </Typography>
                <Typography component="div" variant="body2">
                    &emsp;<span style={{fontWeight: "bold"}}>Obstarávateľ pohrebu:</span> meno
                </Typography>
                <Typography component="div" variant="body2">
                    &emsp;<span style={{fontWeight: "bold"}}>Zosnulý</span>: meno, rodné číslo, mesto pohrebu, prevzaté veci
                </Typography>

            </Popup>
            {success && <Redirect to={{
                pathname: `/pohreb/${success}/info`,
                state: {from: props.location}
            }}/>}
        </form>
    )
}